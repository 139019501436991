import axios from 'axios';
import store from '@/store/';
import {
    Toast
} from 'vant';
const service = axios.create({
    timeout: 20000
});
service.interceptors.request.use(config => {
    if (!(config.url.indexOf('/auth') >= 0)) {
        // store.dispatch('get_access_token', (res) => {
        //     if (res) {
        //         config.headers.Authorization = 'Bearer ' + res;
        //     }
        // });
    }
    return config;
}, error => {
    Promise.reject(error);
})
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res != null && (res.status == 4001 || res.status == 4002)) {
            return response.data;
        } else if (response.status !== 200 && res.status !== 200) {
            Toast(res.message)
        } else {
            return response.data;
        }
    },
    error => {
        Toast(error.response + ',' + error.response.status)
        // if (error.response.status == 403) { //token失败，不做提示
        //     return Promise.reject(error);
        // }
        // try {
        //     Toast(error.response + '状态码为：403')
        // } catch (e) {
        //     Toast(error + '状态码为：403 错误')
        // }
        return Promise.reject(error);
    }
);
export default service;