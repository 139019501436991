<template>
  <div class="flex col WH download" style="background:#eeeef4;">
    <div class="width title absolute pageJump" style="top:0;background:#eeeef4;">
          <van-cell title="电子发票下载" value="返回" is-link to="invoice_records" v-if="path" />
          <van-cell title="电子发票下载" v-else />
    </div>
    <div class="flex1 WH flex col paddingT50">
      <div class="WH flex1 equi_container" style="overflow-y:auto;-webkit-overflow-scrolling: touch;">
        <div class="itemList-wrapper">
            <div class="width itemList marginB10" v-for="(item,key) in invoiceList" :key="key">
                <div class="width relative marginB10 paddingL5 bgfff">
                  <template v-if="invoiceList.length > 0">
                    <van-panel title="电子发票信息"  name="radio" :key="key" class="margin-top:20px">
                    <van-cell title="发票类型" value="增值税普通发票(电子发票)"  />
                    <van-cell style="word-wrap: inherit;height: 10px;padding: 0;">
                      <!-- <span :id="'content'+key" οnpaste="return false" class="selectText" style="color:#fff;display:block;height:0;  
                        " >{{item.path + '/' + item.storeName}}</span>  -->
                      <span :id="'content'+key" class="selectText" style="color:#fff;" >{{item.path + '/' + item.storeName}}</span> 
                    </van-cell>
                    <van-cell>
                    <van-button type="info"  class="btn btn-sm btn-purple" block  :id="'copybtn'+key"  :data-clipboard-target="'#content'+key" @click="copy">点击复制发票链接在浏览器打开下载</van-button>
                    </van-cell>
                    </van-panel>
                  </template>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="toTop" v-show="flag_scroll">
        <van-icon name="upgrade" @click="toTop" />
    </div>
  </div>
</template>
<script>
let Base64 = require('js-base64').Base64
export default {
props:[],
components:{},
data(){
return {
    invoiceList:[],
    flag_scroll: false,
    path:false
}
},
computed:{
},
methods:{
  
  copy(){
    this.$toast('复制成功');
  },
  //回到顶部
  toTop() {
      document.getElementsByClassName('equi_container')[0].scrollTop = 0;
  },
  //监听滚动条，控制回到顶部显示隐藏
  handleScroll(env) {
      let scrollTop = document.getElementsByClassName('equi_container')[0].scrollTop;
      if (scrollTop > 100) {
          this.flag_scroll = true;
      } else {
          this.flag_scroll = false;
      }
  },
  screenWidthCul() {
      this.screenWidth = document.body.clientWidth;
      this.screenHeight = document.body.clientHeight;
      let html = document.getElementsByTagName('html')[0];
      if (this.screenWidth >= 610) {
          this.maxWidth(html);
      } else if (this.screenWidth >= 300 && this.screenWidth < 610) {
          this.autoWidth(html);
      } else if (this.screenWidth <= 300) {
          this.minWidth(html);
      }
  },
  maxWidth(html) {
      html.style.fontSize = '60px';
      html.style.maxWidth = '610px';
      html.style.margin = '0 auto';
  },
  minWidth(html) {
      html.style.minWidth = '300px';
      html.style.fontSize = '30px';
      html.style.margin = '0 auto';
  },
  autoWidth(html) {
      html.style.fontSize = (this.screenWidth / 10) + 'px';
      html.style.margin = '0 auto';
  },
},
destroyed() {
      window.removeEventListener("scroll", this.handleScroll, true);
},
mounted(){
    // 兼容pc端屏幕占满问题
    this.screenWidthCul()
    if (this.$route.query) {
      // 判断进入方式  链接进入
      if (typeof this.$route.query === 'object') { 
        // 链接进入 禁止返回操作
        this.path = false;
        // 截取url中的base64码  http://www.xxx.come/fles?xxxxxxxx
        let url = this.$route.fullPath.split("?")[1];
        // 处理base64码中被url转义的特殊字符
        url = decodeURIComponent(url)
        // slice字符串截取 通过 [  ] 截取内容 
        let beforeIndex = Base64.decode(url).indexOf('[');
        let lastIndex = Base64.decode(url).lastIndexOf(']');
        // 赋值
        this.invoiceList = JSON.parse(Base64.decode(url).slice(beforeIndex,lastIndex+1))
      } else {
        // 路由跳转 
        this.path = true;
        // this.$route.query 赋值
        this.invoiceList = JSON.parse(this.$route.query)
      }
    }
},
}
</script>
<style scoped>
.download .selectText{
  position: absolute;
  top:-99999999999999999999999999px;
}
</style>