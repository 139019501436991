import fetch from '@/api/common/fetch';


//获取openId
export function getOpenId(obj) {
    return fetch({
        url: 'api/WeChatApi/getOpenId',
        method: 'post',
        data: obj
    });
}

//添加开票信息
export function addInvoiceInfo(obj) {
    return fetch({
        url: 'api/WeChatApi/addInvoiceInfo',
        method: 'post',
        data: obj
    });
}

//添加开票信息
export function findInputLast(obj) {
    return fetch({
        url: 'api/WeChatApi/findInputLast',
        method: 'post',
        data: obj
    });
}


//上传图片
export function uploadImage(obj) {
    return fetch({
        url: 'api/WeChatApi/uploadImage',
        method: 'post',
        data: obj
    });
}

//获取开票记录
export function findUserInvoiceByPage(obj) {
    return fetch({
        url: 'api/WeChatApi/findUserInvoiceByPage',
        method: 'post',
        data: obj,
    });
}


// 获取开票记录
export function findUserInvoiceTitles(obj) {
    return fetch({
        url: 'api/WeChatApi/findUserInvoiceTitles',
        method: 'post',
        data: obj,
    });
}

//下拉选择通过 id 查找
export function findInvoiceRecordById(obj) {
    return fetch({
        url: 'api/WeChatApi/findInvoiceRecordById',
        method: 'post',
        data: obj,
    });
}

//删除开票抬头记录
export function deleteRecord(obj) {
    return fetch({
        url: 'api/WeChatApi/deleteRecord',
        method: 'post',
        data: obj,
    });
}

//测试 2020-06-29 syw
// export default {
//     findUserInvoiceByPage: function (obj) {
//         return fetch({
//             url: 'api/WeChatApi/findUserInvoiceByPage',
//             method: 'post',
//             data: obj,
//         });
//     }
// }