<template>
    <div class="WH">
        <router-view></router-view>
    </div>
</template>

<script>
    // @ is an alias to /src
    export let titleText = "主页";


    export default {
        name: 'Home',
        data() {
            return {
                open: false,
                footerShow: true
            }
        },
        mounted() {

        },
        methods: {

        },
        components: {

        }
    }
</script>
<style lang="scss">
    @import "~@/assets/css/standard.scss";
    @import "~@/assets/css/function.scss";
    @import "~@/assets/css/lay-index.scss";
    @import "~@/assets/css/pro-index.scss";
</style>