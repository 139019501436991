<template>
    <div class="flex col" style="background:#eeeef4;">
        <van-collapse v-model="activeNames" class="width">
            <van-collapse-item title="注意事项" name="1">
                <div>尊敬的客户您好，此表单仅用于贵司向我司提交开票申请，请认真阅读以下注意事项：</div>
                <div>1、提交成功并不代表发票能开具，实际需以合同执行情况为准；（一般原则为每月25日之前提交的申请当月开具，25日之后提交的申请次月开具，仅供参考；）</div>
                <div>2、提交前一定确保填写信息准确</div>
            </van-collapse-item>
        </van-collapse>
        <div class="width title pageJump">
            <van-cell title="请填写开票信息" value="我的开票记录" is-link to="invoice_records" />
        </div>
        <div class="width relative marginB10 paddingL5 bgfff">
            <span class="required">*</span>
            <van-cell value="发票类型" />
            <van-field name="radio">
                <template #input>
                    <van-radio-group v-model="listQuery.invoiceType" @change="handleChangeType">
                        <van-radio style="height:1rem;" name="2">增值税专用发票</van-radio>
                        <van-radio style="height:1rem;" name="1">增值税普通发票(电子发票)</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
        </div>
        <div class="width relative marginB10 paddingL5 bgfff invoiceTitle">
            <van-field v-model.trim="listQuery.invoiceTitle" required clearable :right-icon="toggleIcon" label="发票抬头"
                label-width="2rem" placeholder="请填写发票抬头" @blur="getFindInputLastBlur" @change="getFindInputLastBlur"
                @click-right-icon="toggleTitleList" />
        </div>
        <div class="width relative">
            <van-tabs v-model="activeInfo" color="#1989fa" title-active-color="#1989fa">
                <van-tab class="paddingL5 bgfff" title="填写开票信息" name="a">
                    <van-form>
                        <template v-if="listQuery.invoiceType == '1'">
                            <van-field v-model="listQuery.invoiceTfn" required clearable label="税号" label-width="2rem"
                                placeholder="请填写税号" @blur="checkInvoiceTfn" />
                            <van-field v-model="listQuery.invoiceBank" label="开户银行" clearable label-width="2rem"
                                placeholder="请填写开户银行" @blur="checkInvoiceBank" />
                            <van-field v-model="listQuery.bankAccount" label="银行账号" clearable label-width="2rem"
                                placeholder="请填写银行账号" @blur="checkBankAccount" />
                            <van-field v-model="listQuery.registeredAddress" label="开票地址" clearable label-width="2rem"
                                placeholder="请填写开票地址" @blur="checkRegisteredAddress" />
                            <van-field v-model="listQuery.registeredTelephone" label="开票电话" clearable label-width="2rem"
                                placeholder="请填写开票电话" @blur="checkRegisteredTelephone" />
                            <van-field v-model="listQuery.contractNo" label="合同号" clearable label-width="2rem"
                            placeholder="请填写合同号" @blur="checkContractNo" />
                            <van-field v-model="listQuery.amount" label="开票金额" clearable label-width="2rem"
                            placeholder="请填写开票金额" @blur="checkAmount" />
                        </template>
                        <template v-if="listQuery.invoiceType == '2'">
                            <van-field v-model="listQuery.invoiceTfn" required label="税号" clearable label-width="2rem"
                                placeholder="请填写税号" />
                            <van-field v-model="listQuery.invoiceBank" required label="开户银行" clearable
                                label-width="2rem" placeholder="请填写开户银行" @blur="checkInvoiceBank" />
                            <van-field v-model="listQuery.bankAccount" required label="银行账号" clearable
                                label-width="2rem" placeholder="请填写银行账号" @blur="checkBankAccount" />
                            <van-field v-model="listQuery.registeredAddress" required label="开票地址" clearable
                                label-width="2rem" placeholder="请填写开票地址" @blur="checkRegisteredAddress" />
                            <van-field v-model="listQuery.registeredTelephone" required label="开票电话" clearable
                                label-width="2rem" placeholder="请填写开票电话" @blur="checkRegisteredTelephone" />
                            <van-field v-model="listQuery.contractNo" label="合同号" clearable
                            label-width="2rem" placeholder="请填写合同号" @blur="checkContractNo" />
                            <van-field v-model="listQuery.amount" label="开票金额" clearable
                            label-width="2rem" placeholder="请填写开票金额" @blur="checkAmount" />
                        </template>
                    </van-form>
                </van-tab>
                
            </van-tabs>
        </div>
        <div class="width height40 title require">
            <van-cell :title="listQuery.invoiceType == 2 ? '发票邮寄信息' : '联系方式'" :value="listQuery.invoiceType == 2 ?'(必填)' : ''" />
        </div>
        <div class="width">
            <van-form class="paddingL5 bgfff">
                <template v-if="listQuery.invoiceType == 2">
                    <van-field v-model="listQuery.consigneeAdress" required clearable label="收件地址" label-width="2rem"
                    placeholder="请填写收件地址" @blur="checkConsigneeAdress" />
                    <van-field v-model="listQuery.addressee" required clearable label="收件人" label-width="2rem"
                        placeholder="请填写收件人" @blur="checkAddressee" />
                    <van-field v-model="listQuery.receiveCall" required clearable label="收件电话" label-width="2rem"
                        placeholder="请填写收件电话" @blur="checkReceiveCall" />
                    <van-field v-model="listQuery.userRemark" rows="2" autosize clearable label="备注" type="textarea"
                        label-width="2rem" maxlength="100" placeholder="请填写备注" show-word-limit style="min-height:1rem;" />
                </template>
                <template v-else>
                    <van-field v-model="listQuery.addressee"  clearable label="联系人" label-width="2rem"
                        placeholder="请填写联系人"  />
                    <van-field v-model="listQuery.receiveCall"  clearable label="联系电话" label-width="2rem"
                        placeholder="请填写联系电话"  />
                    <van-field v-model="listQuery.userRemark" rows="2" autosize clearable label="备注" type="textarea"
                        label-width="2rem" maxlength="100" placeholder="请填写备注" show-word-limit style="min-height:1rem;" />
                </template>
            </van-form>
        </div>
        <div class="width title padding30 paddingB10">
            <van-button type="info" block @click="checkForm">提交</van-button>
        </div>
        <div class="width height70" style="font-size:14px;text-align:center;line-height:60px;">
            <span class="tip">
                <span class="relative" style="align-items: center;display: flex;">
                    <van-icon class="warnning" name="warning" color="rgb(243, 94, 7)" size="16" />
                    有任何疑问请联系 13811148487(手机号同微信号)</span></span>
        </div>
        <van-popup v-model="isShowTitleList" position="bottom" :style="{ height: '40%' }">
            <van-cell :title="item.invoiceTitle" v-for="(item,index) in invoiceTitleList" @click="selectTitle(item)" :key="index">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                    <van-icon name="clear" color="#e00000" size="18" style="line-height: inherit;"
                        @click.stop="delectTitle(item)" />
                </template>
            </van-cell>
        </van-popup>
    </div>
</template>
<script>
    export let titleText = "开发票";
    import {
        formatDate
    } from '@/api/common/date';
    import {
        addInvoiceInfo,
        getWxCode,
        uploadImage,
        getOpenId,
        findUserInvoiceTitles,
        findInvoiceRecordById,
        findInputLast,
        deleteRecord,
    } from '@/api/invoice/index'
    export default {
        props: [],
        data() {
            return {
                tel: sourceConfig.tel,
                activeNames: ['1'],
                activeInfo: 'a', //tab显示哪个

                listQuery: {
                    invoiceSubmitter: '',
                    invoiceType: '2',
                    invoiceSubject: 1, //1:万维盈创,2:华电智控,3:中科核安
                    invoiceTitle: '', //发票抬头
                    recordId: '',

                    invoiceTfn: '', //税号
                    invoiceBank: '', //开户行
                    bankAccount: '', //银行账号
                    registeredAddress: '', //开票地址
                    registeredTelephone: '', //开票电话
                    contractNo:'',//合同号
                    amount:'',//开票金额

                    consigneeAdress: '',
                    addressee: '',
                    receiveCall: '',
                    userRemark: '',
                    invoiceInfoImage: '',
                },
                fileList: [],
                wxCode: '',
                screenWidth: '',
                screenHeight: '',
                invoiceTitleList: [],
                toggleIcon: 'arrow-down',
                isShowTitleList: false,
                titleArr: []
            }
        },
        created() {},
        mounted() {
            this.screenWidthCul();
            if (window.sessionStorage.getItem("openId") != null && window.sessionStorage.getItem("openId") !=
                undefined) {
                this.listQuery.invoiceSubmitter = JSON.parse(window.sessionStorage.getItem("openId"));
                sessionStorage.setItem('invoiceSubject', this.listQuery.invoiceSubject);
                this.findUserInvoiceTitleList();
            } else {
                // 获取openid
                this.getOpenIdList();
            }
            if (window.sessionStorage.getItem("invoiceSubject") != null && window.sessionStorage.getItem("invoiceSubject") !=
                undefined) {
                    this.listQuery.invoiceSubject = JSON.parse(window.sessionStorage.getItem("invoiceSubject"));
            }else{
                this.listQuery.invoiceSubject = this.getUrlParam('state')==null?1:this.getUrlParam('state');
                sessionStorage.setItem('invoiceSubject', this.listQuery.invoiceSubject);
            }
            window.onresize = () => {
                return (() => {
                    this.screenWidthCul()
                })();
            };
        },
        watch: {
            'isShowTitleList': function (val) {
                this.toggleIcon = val ? 'arrow-up' : 'arrow-down';
            },
            'listQuery.invoiceTitle': function (val, valOld) {
                if (val == '') {
                    this.initData();
                    this.fileList = [];
                }
            },
        },
        methods: {
            //获取微信CODE
            getUrlParam(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i=0;i<vars.length;i++) {
                        var pair = vars[i].split("=");
                        if(pair[0] == variable){return pair[1];}
                }
                return null;
                // var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                // var r = window.location.search.substr(1).match(reg);
                // if (r != null) return unescape(r[2]);
                // return null;
            },
            //获取openId
            getOpenIdList() {
                
                this.wxCode = this.getUrlParam('code');
                this.listQuery.invoiceSubject = this.getUrlParam('state')==null?1:this.getUrlParam('state');
                sessionStorage.setItem('invoiceSubject', this.listQuery.invoiceSubject);


                this.$store.dispatch('change_openId', this.getUrlParam('openId'));
               sessionStorage.setItem('openId', this.getUrlParam('openId'));

                getOpenId({
                    code: this.wxCode,
                    state:this.listQuery.invoiceSubject
                }).then(res => {
                    /* 开发环境测试 ，请使用以下代码 */
                    // this.listQuery.invoiceSubmitter = 'os-CTv9xIsN7D40h-bUG_KDxpaa8';
                    // this.$store.dispatch('change_openId', 'os-CTv9xIsN7D40h-bUG_KDxpaa8');
                    // sessionStorage.setItem('openId', JSON.stringify('os-CTv9xIsN7D40h-bUG_KDxpaa8'));
                    // this.findUserInvoiceTitleList();
                    /* 线上环境测试，请使用以下代码 */
                    if (res.data.openid != null && res.data.openid != undefined && res.data.openid != '') {
                        // 将openId存入sessionStorage
                        sessionStorage.setItem('openId', JSON.stringify(res.data.openid));
                        this.listQuery.invoiceSubmitter = res.data.openid;
                        this.findUserInvoiceTitleList();
                    } else {
                        this.$dialog.alert({
                            title: '提示',
                            message: '用户信息获取失败，请重新获取',
                        }).then(() => {
                            //this.getOpenIdList();
                        });
                    }
                }).catch(err => {
                    this.$dialog.alert({
                        title: '提示',
                        message: '获取用户信息失败，请重新获取',
                    }).then(() => {
                        //this.getOpenIdList();
                    });
                })
            },
            toggleTitleList() {
                this.isShowTitleList = true;
            },
            //删除发票抬头
            delectTitle(item) {
                this.$dialog.confirm({
                    title: '提示',
                    message: '是否删除此发票抬头信息',
                }).then(() => {
                    //请求接口删除
                    deleteRecord({
                        recordId: JSON.stringify(item.recordId)
                    }).then(res => {
                        if (res.code == '20000' && res.flag) {
                            this.$toast.success('操作成功');
                            this.findUserInvoiceTitleList();
                        } else {
                            this.$toast.fail('操作失败');
                        }
                    })
                });
            },
            selectTitle(item) {
                this.listQuery.recordId = item.recordId;
                this.listQuery.invoiceTitle = item.invoiceTitle;
                this.isShowTitleList = false;
                this.getFindInputLast(this.listQuery.recordId)
            },
            findUserInvoiceTitleList() {
                this.invoiceTitleList = [];
                let param = {
                    openid: JSON.parse(window.sessionStorage.getItem("openId"))
                }
                findUserInvoiceTitles(param).then(res => {
                    this.invoiceTitleList = res.code == '20000' && res.data.length > 0 ? res.data : [];
                    this.titleArr = [];
                    this.invoiceTitleList.forEach(el => {
                        this.titleArr.push(el.invoiceTitle);
                    })
                })
            },
            addList() {
                this.$dialog.confirm({
                        title: '提示',
                        message: '提交之后不可更改\n请再次检查填写信息是否准确',
                    })
                    .then(() => {
                        if (this.listQuery.invoiceType == 1) {
                            this.listQuery.consigneeAdress = ''
                        }
                        addInvoiceInfo(this.listQuery).then(res => {
                            if (res.code == '20000' && res.flag) {
                                // this.$toast.success('发票申请提交成功');
                                this.$dialog.alert({
                                    title: '提示',
                                    message: '您的发票申请我司已受理，请耐心等待，一般1-7个工作日会寄出，如确需查询进度，请询13811148487',
                                }).then(() => {
                                    // on close
                                    this.initVoiceTitle();
                                    this.initData();
                                    this.fileList = [];
                                });
                            } else {
                                this.$toast.fail('发票申请提交失败');
                            }
                        })
                    })
                    .catch(() => {

                    });
            },
            getFindInputLastBlur(event) {
                if (JSON.stringify(event.target.value).length > 22) {
                    this.$toast('请填写正确发票抬头长度，不大于20位')
                    return;
                } else {
                    //将invoiceTitle都放到一个数组中，indexOf判断数组中是否存在当前输入的值，存在则请求接口
                    if (this.titleArr.indexOf(this.listQuery.invoiceTitle) != -1) {
                        let param = {
                            openid: JSON.parse(window.sessionStorage.getItem("openId")),
                            invoiceTitle: this.listQuery.invoiceTitle
                        }
                        findInputLast(param).then(res => {
                            this.putValue(res);
                        })
                    } else {
                        this.initData();
                        this.fileList = [];
                    }
                }
            },
            getFindInputLast(value) {
                if (JSON.stringify(value).length > 22) {
                    this.$toast('请填写正确发票抬头长度，不大于20位')
                    return;
                } else {
                    //将invoiceTitle都放到一个数组中，indexOf判断数组中是否存在当前输入的值，存在则请求接口
                    if (this.titleArr.indexOf(this.listQuery.invoiceTitle) != -1) {
                        let param = {
                            openid: JSON.parse(window.sessionStorage.getItem("openId")),
                            recordId: JSON.stringify(this.listQuery.recordId) //转为字符串
                        }
                        findInvoiceRecordById(param).then(res => {
                            this.putValue(res);
                        })
                    } else {
                        this.initData();
                        this.fileList = [];
                    }
                }
            },
            //查询匹配赋值
            putValue(res) {
                if (res.data != null) {
                    this.listQuery.invoiceTfn = res.data.invoiceTfn; //税号
                    this.listQuery.invoiceBank = res.data.invoiceBank; //开户行
                    this.listQuery.bankAccount = res.data.bankAccount; //银行账号
                    this.listQuery.registeredAddress = res.data.registeredAddress; //开票地址
                    this.listQuery.registeredTelephone = res.data.registeredTelephone; //开票电话
                    this.listQuery.contractNo = res.data.contractNo; //合同号
                    this.listQuery.amount = res.data.amount; //开票金额
                    this.listQuery.invoiceSubmitter = res.data.userOpenid;
                    this.listQuery.invoiceTitle = res.data.invoiceTitle;
                    this.listQuery.consigneeAdress = res.data.consigneeAdress;
                    this.listQuery.addressee = res.data.addressee;
                    this.listQuery.receiveCall = res.data.receiveCall;
                }
            },
            //发票类型
            handleChangeType(type) {
                this.listQuery.invoiceType = type;
            },
            beforeUploader(file) {
                let arr = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif'];
                if (!arr.some(item => file.type == item) ) {
                    this.$toast('请上传png/jpg/jpeg/gif/bmp 格式图片');
                    return false;
                }
                return true;
            },
            afterUploader(file) {
                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '图片上传中...',
                });
                let param = {
                    image: file.content
                }
                uploadImage(param).then(res => {
                    this.$toast.clear();
                    if (res.code === 20000) {
                        this.listQuery.invoiceInfoImage = res.data.imagePath;
                        this.fileList[0].url = res.data.imagePath;
                        this.$toast.success('图片上传成功');
                    } else {
                        this.$toast.fail('图片上传失败');
                        this.fileList = []
                    }
                })
            },
            deleteFile(file) {
                this.fileList = []
            },
            initVoiceTitle() {
                this.listQuery.invoiceTitle = ''; //发票抬头
                this.listQuery.recordId = '';
            },
            initData() {
                // 清空填写数据
                this.initDataType();
                this.listQuery.consigneeAdress = ''; //收件地址
                this.listQuery.addressee = ''; //收件人
                this.listQuery.receiveCall = ''; //收件电话
                this.listQuery.userRemark = ''; //备注
            },
            initDataType() {
                this.listQuery.invoiceTfn = ''; //税号
                this.listQuery.invoiceBank = ''; //开户行
                this.listQuery.bankAccount = ''; //银行账号
                this.listQuery.registeredAddress = ''; //开票地址
                this.listQuery.registeredTelephone = ''; //开票电话
                this.listQuery.contractNo = ''; //合同号
                this.listQuery.amount = ''; //开票金额
            },
            checkForm() {
                let RegExp = /^((0\d{2,3}-\d{7,8})|(1\d{10}))$/;
                if (this.listQuery.invoiceTitle == '') {
                    this.$toast('请填写发票抬头')
                    return;
                }
                if (this.activeInfo == 'a') {
                    if (this.listQuery.invoiceTfn == '') {
                        this.$toast('请填写税号')
                        return;
                    }
                } else {
                    if (this.fileList.length == '0') {
                        this.$toast('请上传图片')
                        return;
                    }
                }
                if (this.listQuery.invoiceType == '2') { //专用发票 验证
                    if (this.listQuery.invoiceBank == '') {
                        this.$toast('请填写开户银行')
                        return;
                    }
                    if (this.listQuery.bankAccount == '') {
                        this.$toast('请填写银行账号')
                        return;
                    }
                    if (this.listQuery.registeredAddress == '') {
                        this.$toast('请填写开票地址')
                        return;
                    }
                    if (this.listQuery.registeredTelephone == '') {
                        this.$toast('请填写开票电话')
                        return;
                    }
                    if (RegExp.test(this.listQuery.registeredTelephone) == false) {
                        this.$toast('开票电话号码格式不正确或者位数不正确');
                        return;
                    }
                    if (this.listQuery.consigneeAdress == '') {
                        this.$toast('请填写收件地址')
                        return;
                    }
                    if (this.listQuery.addressee == '') {
                        this.$toast('请填写收件人')
                        return;
                    }
                    if (this.listQuery.receiveCall == '') {
                        this.$toast('请填写收件电话')
                        return;
                    }
                    if (RegExp.test(this.listQuery.receiveCall) == false) {
                        this.$toast('收件电话号码格式不正确或者位数不正确');
                        return;
                    }
                }
                this.addList();
            },
            checkInvoiceTfn(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 50) {
                    this.$toast('税号长度应小于50位')
                    return;
                }
            },
            checkInvoiceBank(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 30) {
                    this.$toast('开户银行长度应小于30位')
                    return;
                }
            },
            checkContractNo(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 30) {
                    this.$toast('合同号长度应小于30位')
                    return;
                }
            },
            checkAmount(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 13) {
                    this.$toast('开票金额长度应小于13位')
                    return;
                }
            },
            checkBankAccount(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 50) {
                    this.$toast('银行账号长度应小于50位')
                    return;
                }
            },
            checkRegisteredAddress(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 50) {
                    this.$toast('开票地址长度应小于50位')
                    return;
                }
            },
            checkRegisteredTelephone(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 20) {
                    this.$toast('开票电话号码长度应小于20位');
                    return;
                }
            },
            checkConsigneeAdress(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 50) {
                    this.$toast('收件地址长度应小于50位')
                    return;
                }
            },
            checkAddressee(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 10) {
                    this.$toast('收件人姓名长度应小于10位')
                    return;
                }
            },
            checkReceiveCall(event) {
                let val = event.target.value;
                if (JSON.stringify(val).length > 15) {
                    this.$toast('收件电话号码长度应小于15位');
                    return;
                }
            },
            screenWidthCul() {
                this.screenWidth = document.body.clientWidth;
                this.screenHeight = document.body.clientHeight;
                let html = document.getElementsByTagName('html')[0];
                if (this.screenWidth >= 610) {
                    this.maxWidth(html);
                } else if (this.screenWidth >= 300 && this.screenWidth < 610) {
                    this.autoWidth(html);
                } else if (this.screenWidth <= 300) {
                    this.minWidth(html);
                }
            },
            maxWidth(html) {
                html.style.fontSize = '60px';
                html.style.maxWidth = '610px';
                html.style.margin = '0 auto';
            },
            minWidth(html) {
                html.style.minWidth = '300px';
                html.style.fontSize = '30px';
                html.style.margin = '0 auto';
            },
            autoWidth(html) {
                html.style.fontSize = (this.screenWidth / 10) + 'px';
                html.style.margin = '0 auto';
            }
        },
        components: {}
    }
</script>
<style lang="scss">
    .title .van-cell {
        background-color: transparent;

        // color: #999;
        .van-cell__title {
            color: #999;
        }

        .van-cell__value {
            color: #1989fa;
        }
    }

    .title.require {
        .van-cell__value {
            color: red;
        }
    }

    .van-tabs__wrap {
        margin-bottom: 10px;
    }

    .required {
        color: #ee0a24;
        top: 14px;
        left: 13px;
        font-size: 14px;
        position: absolute;
        width: 10px;
        z-index: 999;
    }

    .warnning {
        margin-right: 0.1rem;
        margin-bottom: 1px;
    }

    .pageJump .van-cell {
        height: 50px;
        line-height: 50px;
        padding: 0 10px;

        .van-cell__right-icon {
            height: 50px;
            line-height: 50px;
        }
    }

    .tip {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
    }

    textarea.van-field__control {
        min-height: 1rem;
    }

    .van-popup {
        .van-cell {
            cursor: pointer;
            padding: 10px 20px;
        }

        .van-cell:hover {
            background-color: #ececec !important;
        }

        .van-cell__title>span {
            color: #666;
            font-size: 0.32rem;
        }
    }
</style>