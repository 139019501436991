import {
    getInfo,
    getLoginName
} from '@/api/common/login';
import {
    getToken,
    setToken,
    removeToken
} from '@/api/utils/auth';
import {
    Notification
} from 'element-ui';
import store from './../../store/';
const user = {
    state: {
        id: '',
        userName: '',
        token: getToken(),
        roleId: '',
        roleType: '',
        currentNode:'',
    },
    mutations: {
        SET_ID: (state, id) => {
            state.id = id;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_USER_NAME: (state, userName) => {
            state.userName = userName;
        },
        SET_ROLE_ID: (state, roleId) => {
            state.roleId = roleId;
        },
        SET_ROLE_TYPE: (state, roleType) => {
            state.roleType = roleType;
        },
        //set_current_node
        set_current_node: (state, currentNode) => {
            state.currentNode = currentNode;
        },
        
        LOGIN_SUCCESS: () => {
            // console.log('login success')
        }
    },

    actions: {
        // 获取用户信息
        GetInfo({
                    commit,
                    state
                }) {
            return new Promise((resolve, reject) => {
                getInfo().then(response => {
                    const data = response;
                    if(data == null || data == undefined){
                        store.dispatch("delete_user_info");
                        store.dispatch('FedLogOut').then(() => {
                            next({ path: '/login' });
                        })
                        return ;
                    }else if(data != null && data != undefined && data.mes != undefined){
                        Notification({
                            title: '警告',
                            message: data.mes,
                            type: 'warning',
                            duration: 5 * 1000
                        });
                    }
                    const userInfo = data.userInfo;
                    commit('SET_ID', userInfo.userId);
                    commit('SET_USER_NAME', userInfo.userName);
                    commit('SET_ROLE_ID', userInfo.roleId);
                    commit('SET_ROLE_TYPE', userInfo.roleTypeCode);
                    commit('set_current_node', {});
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });

            });
        },
        // 前端 登出
        FedLogOut({
                      commit
                  }) {
            return new Promise(resolve => {
                commit('SET_ID',"");
                commit('SET_USER_NAME', "");
                commit('SET_ROLE_ID', "");
                commit('SET_ROLE_TYPE', "");
                commit('set_current_node',{});
                removeToken();
                resolve();
            });
        },
        
    }
};

export default user;
