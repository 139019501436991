import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import userToken from './user/index';
import commonStore from './common/index';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        userToken,
        commonStore
    },
    getters
});

export default store
