import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


/*import promise from 'es6-promise'
promise.polyfill() //解决axios兼容ie*/

//elementUI 组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
    size: 'mini',
    zindex: 200
});
import 'amfe-flexible/index.js'
import vant from 'vant'
import 'vant/lib/index.css';
Vue.use(vant);
import { Toast } from 'vant';
import echarts from 'echarts'
Vue.prototype.$Toast = Toast;
Vue.prototype.$echarts = echarts;
new ClipboardJS('.btn');
/*import { ImagePreview } from 'vant';
Vue.prototype.$ImagePreview = ImagePreview;
import { Notify } from 'vant';
// Vue.use(Notify);
Vue.prototype.$notify = Notify;*/

/*const whiteList = ['/login'];
router.beforeEach((to, from, next) => {
    store.dispatch('get_access_token', (res) => {
        if (res !== undefined && res) {
            if (to.path === '/login') {
                next();
            } else {
                if (store.getters.id === undefined || store.getters.id === "") {
                    if(store.getters.getLoginName === undefined){//无登录名，则无需请求，直接跳转登录页
                        store.dispatch("delete_user_info");
                        store.dispatch('FedLogOut').then(() => {
                            next({ path: '/login'});
                        })
                    }else{ 
                        store.dispatch('GetInfo').then(info => {
                            if(store.getters.firstMenus.length === 0){
                                store.dispatch("delete_user_info");
                                store.dispatch('FedLogOut').then(() => {
                                    next({ path: '/login'});
                                });
                                myvue.$notify({ type: 'warning', message: '该用户无权限' });
                            }else{
                                next();
                            }
                        }).catch(() => {
                            store.dispatch("delete_user_info");
                            store.dispatch('FedLogOut').then(() => {
                                next({ path: '/login'});
                            })
                        })
                    }
                } else {
                    next();
                }
            }
        } else {
            if (whiteList.indexOf(to.path) !== -1) {
                next()
            } else {
                next('/login');
            }
        }
    });
});*/





//加密
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false;



/*--------------------自动化全局注册start--------------------*/
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
const requireComponent = require.context(
        // 其组件目录的相对路径
        './components/commons',
        // 是否查询其子目录
        true,
        /[a-z]\w+\.(vue|js)$/
    )
    // console.log(requireComponent.keys())
requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName)
        const componentName = upperFirst(
            camelCase(
                fileName.replace(/^\.\/(.*)\.\w+$/, '$1') // 剥去文件名开头的 `./` 和结尾的扩展名
            )
        )
        Vue.component(
            componentName,
            componentConfig.default || componentConfig
        )
    })
    /*--------------------自动化全局注册end --------------------*/


var myvue = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')