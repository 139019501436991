<template>
    <div class="flex col WH" style="background:#eeeef4;">
        <div class="width title absolute pageJump" style="top:0;background:#eeeef4;">
            <van-cell title="我的开票记录" value="我要开票" is-link to="invoice" />
        </div>
        <div class="flex1 WH flex col paddingT50">
            <!--下拉刷新+上划加载更多--->
            <div class="WH flex1 equi_container" style="overflow-y:auto;-webkit-overflow-scrolling: touch;">
                <!-- <van-loading size="24px" vertical type="spinner" v-if="list.length == 0"> 数据加载中...</van-loading> -->
                <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="min-height:100%;">
                    <van-list v-model="loading" :finished="finished" :finished-text="finishText"
                        error-text="请求失败，点击重新加载" @load="onLoad" :offset="100">
                        <div class="itemList-wrapper">
                            <div class="width itemList marginB10" v-for="(item,index) in list" :key="index">
                                <van-cell-group>
                                    <van-cell :value="item.applicationDate">
                                        <!-- 使用 title 插槽来自定义标题 -->
                                        <template #title>
                                            <van-icon name="bell"
                                                :class="item.status == '待开票' ? 'require_bg' : 'green_bg'"
                                                style="margin-right: 5px;padding: 5px 6px 7px 6px;border-radius: 50%;"
                                                color="#fff" />
                                            <span class="custom-title">申请日期</span>
                                        </template>
                                    </van-cell>
                                    <div class="van-hairline--bottom"></div>
                                    <van-cell v-if="item.status == '待开票'" class="require" title="状态"
                                        :value="item.status" />
                                     <van-cell v-if="item.status == '已开票'" class="green" title="状态"
                                        :value="item.status" />
                                      <van-cell v-if="item.status == '待修改'" class="require" title="状态"
                                        >
                                          <template slot="default">
                                                <span class="custom-title">{{item.status}}</span>
                                                <van-tag round  type="primary" class="blue" size="medium" text-color="#000000" @click="editInvoice(item)">修改</van-tag>
                                            </template>
                                      </van-cell>
                                        
                                    <van-panel class="van-panel" title="开票信息" :status="item.invoiceType">
                                        <div class="panel-content">
                                            <span class="list">发票抬头 ： {{item.invoiceTitle?item.invoiceTitle:''}}</span>
                                            <span v-if="item.invoiceTfn" class="list">公司税号 ：
                                                {{item.invoiceTfn?item.invoiceTfn:''}}</span>
                                            <span v-if="item.invoiceBank" class="list">开户银行 ：
                                                {{item.invoiceBank?item.invoiceBank:''}}</span>
                                            <span v-if="item.bankAccount" class="list">银行账号 ：
                                                {{item.bankAccount?item.bankAccount:''}}</span>
                                            <span v-if="item.registeredAddress" class="list">开票地址 ：
                                                {{item.registeredAddress?item.registeredAddress:''}}</span>
                                            <span v-if="item.registeredTelephone" class="list">开票电话 ：
                                                {{item.registeredTelephone?item.registeredTelephone:''}}</span>
                                            <span v-if="item.contractNo" class="list">合同号 ：
                                            {{item.contractNo?item.contractNo:''}}</span>
                                            <span v-if="item.amount" class="list">开票金额 ：
                                            {{item.amount?item.amount:''}}</span>
                                        </div>
                                    </van-panel>
                                    <van-panel :title="item.invoiceType === '增值税普通发票(电子发票)' ? '联系方式' :'邮寄信息'">
                                        <div class="panel-content">
                                            <template v-if="item.invoiceType === '增值税普通发票(电子发票)'">
                                                <span v-if="item.addressee" class="list">联 系 人 ：
                                                    {{item.addressee?item.addressee:''}}</span>
                                                <span v-if="item.receiveCall" class="list">联 系电话 ：
                                                    {{item.receiveCall?item.receiveCall:''}}</span>
                                                <span v-if="item.invoiceMoney"
                                                    class="info">开票金额：{{item.invoiceMoney?item.invoiceMoney:''}}</span>
                                                <span class="list blueDeep" v-if="item.invoiceFile && item.invoiceFile.length > 0" @click="goDowload(item.invoiceFile)">
                                                             电子发票下载链接：点击下载电子发票</span>
                                                 <span v-if="item.invoiceDate"
                                                    class="info">开票日期：{{item.invoiceDate ? item.invoiceDate : ''}}</span>
                                                            
                                            </template>
                                           <template v-else>
                                                <span v-if="item.consigneeAdress" class="list">收件地址 ：
                                                {{item.consigneeAdress?item.consigneeAdress:''}}</span>
                                                <span v-if="item.addressee" class="list">收 件 人 ：
                                                    {{item.addressee?item.addressee:''}}</span>
                                                <span v-if="item.receiveCall" class="list">收件电话 ：
                                                    {{item.receiveCall?item.receiveCall:''}}</span>
                                                <span v-if="item.invoiceMoney"
                                                    class="info">开票金额：{{item.invoiceMoney?item.invoiceMoney:''}}</span>
                                                <span v-if="item.expressCompany"
                                                    class="info">快递公司：{{item.expressCompany?item.expressCompany:''}}</span>
                                                <span v-if="item.expressNumber"
                                                    class="info">快递单号：{{item.expressNumber?item.expressNumber:''}}</span>
                                                <span v-if="item.dealDate"
                                                    class="info">寄件日期：{{item.dealDate?item.dealDate:''}}</span>
                                           </template>
                                        </div>
                                    </van-panel>
                                      <van-panel title="信息照片"  v-if="item.invoiceInfoImage">
                                        <div class="panel-content">
                                            <van-image
                                                width="100"
                                                height="100"
                                                :src="item.invoiceInfoImage" 
                                                @click="imagePreview(item.invoiceInfoImage)"
                                                />
                                        </div>
                                    </van-panel>
                                    <van-cell title="备注" :label="item.userRemark" />
                                </van-cell-group>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
                <van-image-preview v-model="showImage" :images="images"  closeable/>
            </div>
        </div>
        <div class="toTop" v-show="flag_scroll">
            <van-icon name="upgrade" @click="toTop" />
        </div>
    </div>
</template>
<script>
    export let titleText = "发票记录";
    import {
        findUserInvoiceByPage
    } from '@/api/invoice/index'
    export default {
        props: [],
        data() {
            return {
                flag_scroll: false,
                isLoading: false,
                loading: false, //下拉加载中
                finished: false, //下拉结束
                finishText: '', //没有更多了
                total: 0,
                list: [],
                listQuery: {
                    openid: JSON.parse(window.sessionStorage.getItem("openId")),
                    pageSize: 10,
                    pageCurrent: 1
                },
                total: 0,
                isQuery: false,
                isFirstLoad: true,
                showImage: false,
                images: []
                }
        },
        created() {},
        mounted() {
            this.screenWidthCul()
            window.addEventListener('scroll', this.handleScroll, true);
            this.getList();
            window.onresize = () => {
                return (() => {
                    this.screenWidthCul()
                })();
            };
        },
        watch: {

        },
        methods: {
            editInvoice(invoice){
                this.$router.push({ name: 'invoice_edit', params: invoice})
            },
            goDowload(list){
                let data = JSON.stringify(list);
                sessionStorage.setItem('invoice',data)
                this.$router.push({ name: 'download_files',query:list})
            },
            imagePreview(image){
                this.showImage=true;
                this.images=[image];
            }, 
            queryData(flag) {
                if (flag == 'query') {
                    this.list = [];
                    this.isQuery = true;
                    this.listQuery.pageCurrent = 1;
                    this.isLoading = false;
                    this.loading = false;
                    this.finishText = '';
                    this.finished = false;
                } else {
                    this.isQuery = false;
                }
                this.getList();
            },
            getList() {
                findUserInvoiceByPage(this.listQuery).then(res => {
                    if (res.code == '20000') {
                        this.list = this.list.concat(res.data.rows);
                        this.total = res.data.total;
                        if (this.list.length > 0) {
                            this.loading = false;
                            // 如果列表数据条数>=总条数，不再触发滚动加载
                            if (this.list.length >= this.total) {
                                this.loading = false;
                                this.isLoading = false;
                                this.finishText = '没有更多了';
                                this.finished = true;
                            } else {
                                this.listQuery.pageCurrent++;
                            }
                        } else {
                            this.loading = false;
                            this.isLoading = false;
                            this.finishText = '暂无数据';
                            this.finished = true;
                        }
                        this.isQuery = false;
                    } else {
                        this.finishText = '暂无数据';
                        this.finished = true;
                    }
                    if (this.isFirstLoad) {
                        this.isFirstLoad = false;
                    }
                })
            },
            //下滑刷新页面
            onRefresh() {
                this.list = [];
                this.listQuery.pageCurrent = 1;
                this.isLoading = false;
                this.loading = false;
                this.finished = false;
                this.finishText = '';
                this.queryData('query');
            },
            // 上划加载更多
            onLoad() {
                if (!this.isQuery && !this.isFirstLoad) {
                    this.queryData()
                }
            },
            //回到顶部
            toTop() {
                document.getElementsByClassName('equi_container')[0].scrollTop = 0;
            },
            //监听滚动条，控制回到顶部显示隐藏
            handleScroll(env) {
                let scrollTop = document.getElementsByClassName('equi_container')[0].scrollTop;
                if (scrollTop > 100) {
                    this.flag_scroll = true;
                } else {
                    this.flag_scroll = false;
                }
            },
            screenWidthCul() {
                this.screenWidth = document.body.clientWidth;
                this.screenHeight = document.body.clientHeight;
                let html = document.getElementsByTagName('html')[0];
                if (this.screenWidth >= 610) {
                    this.maxWidth(html);
                } else if (this.screenWidth >= 300 && this.screenWidth < 610) {
                    this.autoWidth(html);
                } else if (this.screenWidth <= 300) {
                    this.minWidth(html);
                }
            },
            maxWidth(html) {
                html.style.fontSize = '60px';
                html.style.maxWidth = '610px';
                html.style.margin = '0 auto';
            },
            minWidth(html) {
                html.style.minWidth = '300px';
                html.style.fontSize = '30px';
                html.style.margin = '0 auto';
            },
            autoWidth(html) {
                html.style.fontSize = (this.screenWidth / 10) + 'px';
                html.style.margin = '0 auto';
            },
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll, true);
        },
        components: {},
    }
</script>
<style lang="scss">
    .toTop {
        position: absolute;
        right: 10px;
        bottom: 30px;
        color: #1989fa;
        font-size: 36px;
    }

    .require {
        .van-cell__value {
            color: red;
        }
    }

    .require_bg {
        background-color: red;
    }

    .green_bg {
        background-color: #07da04;
    }
    .blue_bg {
        background-color: blue;
    }

    .green {
        .van-cell__value {
            color: #07da04;
            
        }
    }
     .blue {
           // color: blue;
            margin-left: 0.34rem;
        
    }


    .pageJump .van-cell {
        height: 50px;
        line-height: 50px;
        padding: 0 10px;

        .van-cell__right-icon {
            height: 50px;
            line-height: 50px;
        }


    }

    .van-panel__header-value {
        color: #969799 !important;
    }

    .van-panel .van-cell:not(:last-child)::after {
        border-bottom: 0px;
    }

    .panel-content {
        color: #969799;
        font-size: 12px;
        padding: 0 15px 10px;

        .list {
            display: block;
        }

        .info {
            color: #0068d2;
            margin-right: 20px;
            display: inline-block;
        }
    }

    .itemList-wrapper {
        .van-cell__title {
            color: #323233;
        }
    }
</style>