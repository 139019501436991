import Vue from 'vue'
import VueRouter from 'vue-router'
import Home, {
    titleText
} from '@/views/Home.vue'

Vue.use(VueRouter)

const requireComponent = require.context('@/views/bill', true, /\.vue$/);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) { //相同路径跳转的兼容问题
    return routerPush.call(this, location).catch(error => error)
}
const routes = [{
        path: '/',
        redirect: {
            name: 'invoice'
        }
    },
    {
        path: '/',
        name: 'invoice',
        meta: {
            'linkName': titleText
        },
        component: Home,
        children: [] //children 对应 require.context('@/components/{xxx}')
    },
    /* {
       path: '/about',
       name: 'about',
       // route level code-splitting
       // this generates a separate chunk (about.[hash].js) for this route
       // which is lazy-loaded when the route is visited.
       component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
     }*/
];

//为当前路径不跳转 处理
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const homeIndex = routes.length - 1; //home位置 下方有用
//路由自动化注册
const route_base = requireComponent.keys().map(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName.replace(/^\.\//, '').replace(/\.vue$/, ''); // 剥去文件名开头的 `./` 和`.vue`结尾的扩展名
    const componentNameRe = componentName.replace(/\//g, '-');
    const component = Vue.component(componentNameRe, componentConfig.default || componentConfig);
    const result = {
        path: '/' + componentName, //control/calibration/advanced
        name: componentNameRe, // control-calibration-advanced
        meta: {
            'titleText': componentConfig.titleText
        },
        component,
        children: []
    };
    const arr = componentNameRe.split('-');
    if (arr.length > 1) {
        equivalArray(arr, result); // arr = ["control", "calibration", "advanced"] , result = path/name/meta/component
    } else {
        routes[homeIndex].children.push(result); //无父级路由子项由此进入
    }
    return result
});

function equivalArray(arr, result) {
    arr.pop();
    var pname = arr.join("-");
    recursiveTraverse(routes[homeIndex], (node) => {
        if (node.name === pname) { //父级匹配
            node.children.push(result);
        }
    })
}
//利用递归与引用值特性
function recursiveTraverse(node, action) {
    if (!node || !node.children) {
        return;
    }
    action(node);
    node.children.forEach(function (item, index) {
        recursiveTraverse(item, action);
    });
}



const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

export default router