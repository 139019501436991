import fetch from './fetch';
import store from '@/store/';
import PcCookie from './cookies.js';
import CryptoJS from 'crypto-js'

const LOGIN_USER_KEY = "rememberMe";
export function refreshToken() {
    return fetch({
        method: 'post',
        url: "/ydjc/auth/refreshToken",
        auth: {
            username: 'dcloud-client-auth',
            password: 'wanweiClientSecret'
        },
        // data: 'refreshToken='+ store.getters.getRefreshToken+'&accessToken='+ store.getters.getAccessToken
    });
}

export function updateUserLoginCookie(rememberMe, loginForm) {
    if (rememberMe) {
        var userObject = {
            "username": loginForm.username,
            "password": loginForm.password
        };
        var userStr = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(userObject)));
        PcCookie.set({
            key: LOGIN_USER_KEY,
            value: userStr,
            expires: 365
        });
    } else {
        PcCookie.delete({
            key: LOGIN_USER_KEY
        });
    }
}
export function getUserLoginCookie() {
    const userCookie = PcCookie.get(LOGIN_USER_KEY);
    if (userCookie != undefined) {
        const user64 = CryptoJS.enc.Base64.parse(userCookie);
        const userStr = user64.toString(CryptoJS.enc.Utf8);
        if (userStr != undefined) {
            const userObject = JSON.parse(userStr);
            if (userObject != undefined && userObject != null && userObject.username != undefined) {
                return userObject;
            }
        }
    }
    return null;
}

export function ywLogin(loginName, loginPwd) {
    return fetch({
        method: 'POST',
        url: '/ydjc/auth/login',
        auth: {
            username: 'dcloud-client-auth',
            password: 'wanweiClientSecret'
        },
        data: {
            loginName: loginName,
            password: loginPwd
        }
    })
}

export function getInfo() {
    return fetch({
        url: '/ydjc/user/getUserLoginInfoApp',
        method: 'post'
    });
}

export function updatePassWord(obj) {
    return fetch({
        url: '/ydjc/user/updatePassWord',
        method: 'post',
        data: obj
    });
}