import userToken from "./user";

const getters = {
    id: state => state.user.id,
    token: state => state.user.token,
    userName: state => state.user.userName,
    roleId: state => state.user.roleId,
    roleType: state => state.user.roleType,
    currentNode: state => state.userToken.currentNode,
    openId: state => state.commonStore.openId
};
export default getters