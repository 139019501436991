const state = {
    loadingState: 1,
    openId: '',
}

const mutations = {
    update_loadingStateS(state) {
        state.loadingState = (!state.loadingState) * 1 // 强制转换为布尔值然后为Number0和1显示，2隐藏，用3个数主要是用于方便监听
    },
    update_loadingStateH(state) {
        state.loadingState = 2 // 2是隐藏
    },
    update_openId(state, openId) {
        state.openId = openId
    }
}

const actions = {
    change_loadingStateS({ commit }) {
        commit('update_loadingStateS');
    },
    change_loadingStateH({ commit }) {
        commit('update_loadingStateH');
    },
    change_openId({ commit }, openId) {
        commit('update_openId', openId);
    }
}

export default {
    state,
    actions,
    mutations
}