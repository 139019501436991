import Cookies from 'js-cookie';

class PcCookie {
    constructor() {
        this.Prefix = 'wanwei_dcloud-ydjc_';
        this.expireTime = 7200;
    }

    set(cookieParam) {
        let {key, value, expires, path, success} = cookieParam;
        key = this.Prefix + key;
        Cookies.set(key, value, {expires: expires || this.expireTime});
        success && success();
    }

    get(key) {
        return Cookies.get(this.Prefix + key);
    }

    delete (cookieParam) {
        let {key, path, success} = cookieParam;
        Cookies.remove(this.Prefix + key);
        success && success();
    }

    geteAll () {
        return Cookies.get();
    }

}

export default new PcCookie();
