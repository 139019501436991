import PcCookie from '@/api/common/cookies';
import store from '@/store/';
import CryptoJS from 'crypto-js'
import { refreshToken } from '@/api/common/login';

const enums = {
    USER: {
        LOGIN_NAME: 'LOGIN_NAME',
        AUTH_TOKEN: 'AUTH_TOKEN',
        REFRESH_TOKEN: 'REFRESH_TOKEN'
    }
};

const state = {
    loginName: '',
    currentNode: null,
    rememberMe: false,
    authToken: {
        access_token: '',
        expires_in: '',
        timestamp: ''
    },
    refreshToken: {
        refresh_token: ''
    }
};

const getters = {
    getCurrentNode(state) {
        return state.currentNode;
    },
    getLoginName(state) {
        if (!state.loginName) {
            state.loginName = PcCookie.get(enums.USER.LOGIN_NAME);
        }
        return state.loginName;
    },
    getRefreshToken: (state) => {
        if (!state.refreshToken || state.refreshToken.refresh_token === '') {
            state.refreshToken = PcCookie.get(enums.USER.REFRESH_TOKEN) ? JSON.parse(PcCookie.get(enums.USER.REFRESH_TOKEN)) : {};
        }
        return state.refreshToken.refresh_token;
    },
    getAccessToken: (state) => {
        if (!state.authToken || state.authToken.access_token === '') {
            state.authToken = PcCookie.get(enums.USER.AUTH_TOKEN) ? JSON.parse(PcCookie.get(enums.USER.AUTH_TOKEN)) : {};
        }
        return state.authToken.access_token;
    },
    getAuthToken: (state) => {
        if (!state.authToken || state.authToken.access_token === '') {
            state.authToken = PcCookie.get(enums.USER.AUTH_TOKEN) ? JSON.parse(PcCookie.get(enums.USER.AUTH_TOKEN)) : {};
        }
        return state.authToken;
    }
};

const mutations = {
    setCurrentNode(state, node) {
        state.currentNode = node;
    },
    get_login_name(state) {
        if (!state.loginName) {
            state.loginName = PcCookie.get(enums.USER.loginName);
        }
        return state.loginName;
    },
    updateUserInfo(state, data) {
        state.loginName = data.loginName;
        PcCookie.set({
            key: enums.USER.LOGIN_NAME,
            value: data.loginName
        });
    },
    updateAuthToken(state, authToken) {
        state.authToken = authToken;
        let expires = 0.5;
        delete authToken['jti'];
        delete authToken['token_type'];
        let refreshToken = {};
        Object.assign(refreshToken, authToken);
        delete authToken['refresh_token'];
        delete refreshToken['access_token'];
        state.refreshToken = refreshToken;
        PcCookie.set({
            key: enums.USER.AUTH_TOKEN,
            value: authToken,
            expires: expires
        });
        PcCookie.set({
            key: enums.USER.REFRESH_TOKEN,
            value: refreshToken,
            expires: expires
        });
    },
    deleteUserInfo(state) {
        PcCookie.delete({
            key: enums.USER.LOGIN_NAME
        });
        state.loginName = '';
    },
    deleteAuthToken(state) {
        state.authToken = {};
        PcCookie.delete({
            key: enums.USER.AUTH_TOKEN
        });
    },


    updateRedirectUri(state, redirectUri) {
        state.redirectUri = redirectUri;
        PcCookie.set({
            key: enums.USER.REDIRECT_URI,
            value: redirectUri
        });
    }
};

const actions = {
    set_current_node({ commit }, node) {
        commit('setCurrentNode', node);
    },
    get_login_name({ commit }) {
        return commit('get_login_name');
    },
    get_access_token({commit}, cb) {
        if (!state.refreshToken || state.refreshToken.refresh_token === '') {
            state.refreshToken = PcCookie.get(enums.USER.REFRESH_TOKEN) ? JSON.parse(PcCookie.get(enums.USER.REFRESH_TOKEN)) : {};
        }
        if (!state.authToken || state.authToken.access_token === '') {
            state.authToken = PcCookie.get(enums.USER.AUTH_TOKEN) ? JSON.parse(PcCookie.get(enums.USER.AUTH_TOKEN)) : {};
        }
        if (state.authToken.access_token) {
            if ((new Date().getTime() - state.authToken.timestamp) > 100 * 60 * 1000) {
                commit('deleteUserInfo');
                commit('deleteAuthToken');
                location.reload();
            }
            // }else{
            //     refreshToken().then(res => {
            //         if (res.status === 200) {
            //             commit('updateAuthToken',JSON.parse(res.data));
            //         } else {
            //             commit('deleteUserInfo');
            //             commit('deleteAuthToken');
            //             location.reload();
            //         }
            //     });
            // }
        }
        cb && cb(state.authToken.access_token);
    },
    update_user_info({ commit }, data) {
        commit('updateUserInfo', data);
    },
    delete_user_info({ commit }, loginName) {
        commit('deleteUserInfo', loginName);
        commit('deleteAuthToken');
    },
    update_auth_token({ commit }, authToken) {
        commit('updateAuthToken', authToken);
    },
    update_redirect_uri({ commit }, redirectUri) {
        commit('updateRedirectUri', redirectUri);
    }
};


export default {
    state,
    getters,
    actions,
    mutations
};
